import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, StoreService, LoggerService, LoaderService, MenuConfigService, LayoutUtilsService } from './shared/services';
import { MenuConfig } from './menu.config';
import { environment } from '../environments/environment';
import { interval } from "rxjs";
import { ConstantPool } from '@angular/compiler';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { FirebaseHandlersService } from './shared/services/firebase-handlers.service';
import { AlertEntityDialogComponent } from './shared/components/modals/alert-entity-dialog/alert-entity-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public showLoader: boolean = false;
  public showError: boolean = true;
  private repeatIntervalSeconds: number = 60;
  private dataSeenOnce: boolean = false;
  private messageSeenOnce: boolean = false;
  private idleState = 'Not started.';
  private activityDialog: any = undefined;
  private aliveAppIntervalMinutes: number = environment.aliveAppIntervalMinutes;
  private aliveCheckAppIntervalMinutes: number = environment.aliveCheckAppIntervalMinutes;
  private message;
  constructor(private menuConfigService: MenuConfigService, private loaderService: LoaderService,
    public snackBar: MatSnackBar, private translate: TranslateService, private requestService: RequestService,
    private logger: LoggerService, private router: Router, private dialog: MatDialog, private layoutUtilsService: LayoutUtilsService,
    private idle: Idle,
    private storeService: StoreService, private activatedRoute: ActivatedRoute, private route: ActivatedRoute, private firebaseService: FirebaseHandlersService) {
    translate.setDefaultLang('en');

    this.getMe();
    this.setIdleIntervals();
  }

  ngOnInit() {

    this.subscriptions.push(
      this.requestService.authenticatedUser.subscribe((event: boolean) => {
        if (event) {
          let rememberMe = false;
          if (localStorage.getItem('rememberMe')) {
            rememberMe = JSON.parse(localStorage.getItem('rememberMe') || '');
          }
          if (!rememberMe) {
            this.resetActivity();
          } else {
            this.idle.stop();
            console.log('Idle Activity Cancelled');
          }
          // console.log('authenticatedUser' ,event);
          // console.log('this.dataSeenOnce' ,this.dataSeenOnce);
          if (!this.dataSeenOnce) {
            this.getMe();
          }
        } else {
          this.idle.stop();
        }
      }
      ));

    // this.subscriptions.push(interval(1000 * this.repeatIntervalSeconds).subscribe(() => {
    //   if (this.requestService.authenticatedUser.getValue()) {
    //     if (!this.requestService.validateMe()) {
    //       // window.location.reload();
    //     }
    //   }
    // }));
    this.subscribeActivity();

    this.subscriptions.push(
      this.requestService.appStatusSubject.subscribe((data: any) => {
        if (data) {
          if (data === 'login') {
            // this.requestService.logout();
            this.firebaseService.logout(true);
          }
        }
      })
    );
    this.subscriptions.push(
      this.router.events.subscribe((event: any) => {

        if (event instanceof NavigationStart) {
          // Show loading indicator
          this.loaderService.display(false);
        }

        if (event instanceof NavigationEnd) {
          // Hide loading indicator
        }

        if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log('NavigationError:', event.error);
        }
      })
    );
    this.subscriptions.push(
      this.logger.errorObject.subscribe((error) => {
        if (error) {
          // console.log('Global Error: ', error);
          // this.loaderService.display(false);
          if (this.showError) {
            this.showError = false;
            // console.log(this.translate.instant('An Error occured:') + error);
            this.openAlert(this.translate.instant('You are experiencing a glitch'));
          }
        }
      })
    );
    this.menuConfigService.loadConfigs(new MenuConfig().configs);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  getQueryParam(param) {
    var result = window.location.href.match(
      new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)")
    );

    return result ? result[3] : false;
  }
  public getMe() {
    // let urlToken = this.getQueryParam('token');//this.route.snapshot.queryParams['token'] || '';
    if (localStorage.getItem('currentUser') && localStorage.getItem('o') && localStorage.getItem('a') && localStorage.getItem('l')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      let orgId = JSON.parse(localStorage.getItem('o'));
      let appId = JSON.parse(localStorage.getItem('a'));
      let locId = JSON.parse(localStorage.getItem('l'));
      let orgData = JSON.parse(localStorage.getItem('org'));
      this.requestService.orgId = orgId;
      this.requestService.appId = appId;
      this.requestService.locId = locId;
      this.requestService.pageOrganization.next(orgData);
      this.requestService.currentUser = currentUser;
      let resource = this.requestService.getItemFromListContains(currentUser.resources, orgId, 'organizationId');
      if (resource) {
        this.requestService.updatePermissions(resource._id);
        this.dataSeenOnce = true;
        this.validateMeByApi();
        try {
          if (Notification.permission === "granted") {
            this.firebaseService.registerDevice(currentUser._id);
          }
          else {
            const dialog = this.dialog.open(AlertEntityDialogComponent, {
              data: {
                description: this.translate.instant(
                  'Notifications were blocked by the browser. Enable to receive notifications.'
                ),
                title: '',
              },
              width: 'fit-content',
            });
            dialog.afterClosed().subscribe((result) => {
              // if (result) {
              Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                  // console.log('Notifica÷tion permission granted.');
                  this.firebaseService.registerDevice(currentUser._id);
                }
                // else {
                //   this.layoutUtilsService.showNotification(this.translate.instant('Notifications were blocked by the browser. Enable to receive notifications.'), 'Dismiss');
                // }
              });

              // }
              // else {
              //   this.layoutUtilsService.showNotification(this.translate.instant('Notifications were blocked by the browser. Enable to receive notifications.'), 'Dismiss');
              // }
            });
          }
        } catch (e) {
          // do nothing
        }
      }
    } else {
      this.dataSeenOnce = false;

    };


  }
  public validateMeByApi() {
    this.requestService.getMe((data, error) => {
      if (error) {
        // this.requestService.logout();
        //console.log(error);
      }
      if (data) {
        sessionStorage.setItem('live', JSON.stringify(true));
        this.requestService.authenticatedUser.next(true);
        // valid
      } else {
        this.dataSeenOnce = false;
        // this.requestService.logout();
      }
    });
  }
  public openAlert(message, title = 'Error') {
    const _title: string = title;
    const _description: string = message;

    const dialogRef = this.layoutUtilsService.errorElement(_title, _description);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      window.location.reload();
    });
  }

  setIdleIntervals() {
    if (localStorage.getItem('idleInterval') && localStorage.getItem('timeoutInterval')) {
      this.aliveCheckAppIntervalMinutes = JSON.parse(localStorage.getItem('idleInterval'));
      this.aliveAppIntervalMinutes = JSON.parse(localStorage.getItem('timeoutInterval'));
    } else {
      localStorage.setItem('idleInterval', JSON.stringify(this.aliveCheckAppIntervalMinutes));
      localStorage.setItem('timeoutInterval', JSON.stringify(this.aliveAppIntervalMinutes))
    }
  }

  resetActivity() {
    this.idle.watch();
    this.idleState = 'Running.';
    console.log('Idle Activity', this.idleState);
  }

  subscribeActivity() {
    console.log('Idle Activity initialized');
    this.idle.setIdle(this.aliveCheckAppIntervalMinutes * 60);
    this.idle.setTimeout(this.aliveAppIntervalMinutes * 60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.subscriptions.push(
      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.'
        console.log(this.idleState);
        this.resetActivity();
      })
    );
    this.subscriptions.push(
      this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        console.log(this.idleState);
        this.dataSeenOnce = false;
        this.activityDialog = undefined;
        // this.requestService.logout();
        this.firebaseService.logout();

      })
    );
    this.subscriptions.push(
      this.idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.log(this.idleState);
        this.noActivity(this.translate.instant('No Activity'), this.translate.instant('You have been idle for a long time. Please log in again to continue'));
      })
    );
    this.subscriptions.push(
      this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!'
        console.log(this.idleState);
      })
    );
  }

  noActivity(title: string, msg: string) {
    if (!this.activityDialog) {
      let alertSetting = {};
      alertSetting['overlayClickToClose'] = false;
      alertSetting['showCloseButton'] = false;
      alertSetting['confirmText'] = this.translate.instant('Ok');
      // alertSetting['declineText'] = this.translate.instant('No');
      // alertSetting['timerEvent'] = 120;

      this.activityDialog = this.layoutUtilsService.alertActionElement(title, msg, alertSetting);
      this.activityDialog.afterClosed().subscribe(res => {
        // if (res) {
        // if(res.action === 'declineText'){
        //   // this.subscribeActivity(); // do nothing
        // }else
        // if (res.action === 'confirmText') {
        //   this.activityDialog.close();
        //   this.resetActivity();
        // } else {
        //   this.requestService.logOutApi();
        // }
        // this.activityDialog = undefined;
        // }
        this.dataSeenOnce = false;
        this.activityDialog = undefined;
        // this.requestService.logout();
        this.firebaseService.logout();
      });
    }
  };
}
